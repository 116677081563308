<template>
  <div>
    <div v-if="commentsList">
      <div
        class="comment"
        v-for="(comment, index) in commentsList"
        :key="index"
        @click="toCommentDetail(comment.comment_id)"
      >
        <div class="comment__avatar">
          <img
            :src="comment.user_avatar ? comment.user_avatar : $userInfo.avatar"
          />
        </div>
        <div class="comment__detail">
          <div class="comment__detail__top">
            <div class="comment__detail__top__name van-ellipsis">
              {{
                comment.user_nickname
                  ? comment.user_nickname
                  : $userInfo.nick_name
              }}
            </div>
            <div class="comment__detail__top__operate" v-if="comment.like_count">
              <span @click.stop="$userSetting ? toGood(comment, index) : ''">
                <van-icon
                  :name="comment.likes == 1 ? 'good-job' : 'good-job-o'"
                  :color="comment.likes == 1 ? 'red' : ''"
                />
                {{ comment.like_count }}
              </span>
              <span @click.stop="$userSetting ? toBad(comment, index) : ''">
                <van-icon
                  :name="comment.likes == 2 ? 'good-job' : 'good-job-o'"
                  :color="comment.likes == 2 ? '#666' : ''"
                  class="comment__detail__top__operate--noGood"
                />
                {{ comment.dislike_count }}
              </span>
              <span>
                <van-icon name="comment-o" />
                {{ comment.comment_count }}
              </span>
            </div>
          </div>
          <div class="comment__detail__content">{{ comment.content }}</div>
        </div>
      </div>
    </div>
    <div v-else style="color: #999; text-align: center">没有啦</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

// 赞、踩、评论
const useOperationEffect = () => {
  const store = useStore();
  const toGood = async (comment, index) => {
    let method = "";
    switch (comment.likes) {
      case 0:
        method = "post";
        break;
      case 2:
        return Toast("您已经踩了");
      default:
        method = "delete";
        break;
    }
    await store.dispatch("comment/postCommentLike", {
      index: index,
      id: comment.comment_id,
      likes: 1,
      method,
    });
  };
  const toBad = async (comment, index) => {
    let method = "";
    switch (comment.likes) {
      case 0:
        method = "post";
        break;
      case 1:
        return Toast("您已经赞了");
      default:
        method = "delete";
        break;
    }
    await store.dispatch("comment/postCommentLike", {
      index: index,
      id: comment.comment_id,
      likes: 2,
      method,
    });
  };
  return { toBad, toGood };
};

export default {
  props: ["commentsList"],
  setup() {
    const router = useRouter();
    const { toBad, toGood } = useOperationEffect();
    const toCommentDetail = (commentId) => {
      router.push({
        name: "commentDetail",
        query: {
          id: commentId,
        },
      });
    };
    return { toBad, toGood, toCommentDetail };
  },
};
</script>

<style lang="less" scoped>
.comment {
  display: flex;
  margin: 0 20px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  letter-spacing: 0.5px;
  &__avatar {
    flex: 1;
    text-align: right;
    padding-right: 10px;
    min-width: 30px;
    max-width: 50px;
    min-height: 30px;
    max-height: 50px;
    img {
      width: 65%;
      height: 65%;
      border-radius: 50%;
    }
  }
  &__detail {
    flex: 3;
    &__top {
      display: flex;
      margin: 12px 0;
      font-size: 13px;
      &__name {
        flex: 3;
        font-weight: 500;
        color: #00a8ff;
        width: 30px;
      }
      &__operate {
        flex: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-width: 90px;
        max-width: 120px;
        color: #666;
        &--noGood {
          transform: rotate(180deg);
          line-height: 12px;
        }
      }
    }
    &__content {
      line-height: 1.2;
      font-size: 14px;
    }
  }
}
</style>
