<template>
  <div class="card__wrap">
    <div class="card__wrap__title van-multi-ellipsis--l2">{{ news.title }}</div>
    <div class="card__wrap__img">
      <div class="card__wrap__img__item" v-for="item in news.media" :key="item">
        <img
          :src="item"
          v-if="$userSetting ? $userSetting.use_mode == 1 : false"
        />
      </div>
    </div>
    <div
      v-if="$userSetting ? $userSetting.use_mode == 2 : true"
      class="card__wrap__img__item--nopic"
    >
      省流模式不显示图片
    </div>
    <div class="card__wrap__subtitle">
      <!-- <div class="card__wrap__subtitle__item">新华社</div> -->
      <!-- <div class="card__wrap__subtitle__item">345评论</div> -->
      <div class="card__wrap__subtitle__item">{{ news.publish_time }} 发布</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["news"],
};
</script>

<style lang="less" scoped>
.card__wrap {
  margin: 20px;
  border-bottom: 1px solid #f2f2f2;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 15px;
  &__img {
    display: flex;
    padding-top: 10px;
    &__item {
      flex: 1;
      text-align: center;
      img {
        width: 90%;
        height: 100%;
        display: block;
        border-radius: 2px;
        margin: 0 auto 8px;
      }
      &--nopic {
        font-size: 80%;
        font-weight: bold;
        color: #c8c8c8;
        height: 50px;
        background: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__subtitle {
    display: flex;
    color: #999;
    font-size: 50%;
    padding-top: 10px;
    width: 70%;
    &__item {
      flex: 1;
    }
  }
}
</style>